import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _353d18e0 = () => interopDefault(import('..\\pages\\a.vue' /* webpackChunkName: "pages/a" */))
const _b52247fc = () => interopDefault(import('..\\pages\\connorsTeam\\index.vue' /* webpackChunkName: "pages/connorsTeam/index" */))
const _54e58521 = () => interopDefault(import('..\\pages\\mailVerify.vue' /* webpackChunkName: "pages/mailVerify" */))
const _9046812c = () => interopDefault(import('..\\pages\\molds\\index.vue' /* webpackChunkName: "pages/molds/index" */))
const _1d711a42 = () => interopDefault(import('..\\pages\\msg\\index.vue' /* webpackChunkName: "pages/msg/index" */))
const _6075ce15 = () => interopDefault(import('..\\pages\\order\\index.vue' /* webpackChunkName: "pages/order/index" */))
const _6cf8eede = () => interopDefault(import('..\\pages\\personal.vue' /* webpackChunkName: "pages/personal" */))
const _7b5b6e27 = () => interopDefault(import('..\\pages\\quote\\index.vue' /* webpackChunkName: "pages/quote/index" */))
const _7edaf263 = () => interopDefault(import('..\\pages\\test.vue' /* webpackChunkName: "pages/test" */))
const _995955c8 = () => interopDefault(import('..\\pages\\user.vue' /* webpackChunkName: "pages/user" */))
const _5bccc0b7 = () => interopDefault(import('..\\pages\\user\\password.vue' /* webpackChunkName: "pages/user/password" */))
const _48cc5cbc = () => interopDefault(import('..\\pages\\connorsTeam\\newQuote.vue' /* webpackChunkName: "pages/connorsTeam/newQuote" */))
const _3ed449d6 = () => interopDefault(import('..\\pages\\enterprise\\register.vue' /* webpackChunkName: "pages/enterprise/register" */))
const _7ca0913b = () => interopDefault(import('..\\pages\\enterprise\\rfq.vue' /* webpackChunkName: "pages/enterprise/rfq" */))
const _425d3242 = () => interopDefault(import('..\\pages\\help-center\\specifications-policy.vue' /* webpackChunkName: "pages/help-center/specifications-policy" */))
const _58cfd8ae = () => interopDefault(import('..\\pages\\landing-page\\cnc-machining.vue' /* webpackChunkName: "pages/landing-page/cnc-machining" */))
const _951bb07e = () => interopDefault(import('..\\pages\\landing-page\\competitors.vue' /* webpackChunkName: "pages/landing-page/competitors" */))
const _ddb3daa2 = () => interopDefault(import('..\\pages\\member\\agreement.vue' /* webpackChunkName: "pages/member/agreement" */))
const _067c09df = () => interopDefault(import('..\\pages\\member\\findPassword.vue' /* webpackChunkName: "pages/member/findPassword" */))
const _681abd12 = () => interopDefault(import('..\\pages\\member\\joinTeam.vue' /* webpackChunkName: "pages/member/joinTeam" */))
const _3b7711e4 = () => interopDefault(import('..\\pages\\member\\login.vue' /* webpackChunkName: "pages/member/login" */))
const _a5975726 = () => interopDefault(import('..\\pages\\member\\privacy.vue' /* webpackChunkName: "pages/member/privacy" */))
const _88a8c1e4 = () => interopDefault(import('..\\pages\\member\\register.vue' /* webpackChunkName: "pages/member/register" */))
const _6645184e = () => interopDefault(import('..\\pages\\member\\registerBak.vue' /* webpackChunkName: "pages/member/registerBak" */))
const _b12f21a2 = () => interopDefault(import('..\\pages\\member\\resetPassword.vue' /* webpackChunkName: "pages/member/resetPassword" */))
const _b5f57b3e = () => interopDefault(import('..\\pages\\member\\thank-you.vue' /* webpackChunkName: "pages/member/thank-you" */))
const _4697041e = () => interopDefault(import('..\\pages\\order\\detail.vue' /* webpackChunkName: "pages/order/detail" */))
const _cbff7fee = () => interopDefault(import('..\\pages\\order\\orderSubmit.vue' /* webpackChunkName: "pages/order/orderSubmit" */))
const _62863067 = () => interopDefault(import('..\\pages\\order\\paybank.vue' /* webpackChunkName: "pages/order/paybank" */))
const _a56a42ee = () => interopDefault(import('..\\pages\\order\\payment.vue' /* webpackChunkName: "pages/order/payment" */))
const _2237165d = () => interopDefault(import('..\\pages\\order\\paystatus.vue' /* webpackChunkName: "pages/order/paystatus" */))
const _257bf270 = () => interopDefault(import('..\\pages\\order\\print.vue' /* webpackChunkName: "pages/order/print" */))
const _86c45aca = () => interopDefault(import('..\\pages\\order\\utils.js' /* webpackChunkName: "pages/order/utils" */))
const _0dabc5a9 = () => interopDefault(import('..\\pages\\quote\\blank.vue' /* webpackChunkName: "pages/quote/blank" */))
const _7b3dac01 = () => interopDefault(import('..\\pages\\quote\\checkout.vue' /* webpackChunkName: "pages/quote/checkout" */))
const _a953fb0a = () => interopDefault(import('..\\pages\\quote\\checkoutBak.vue' /* webpackChunkName: "pages/quote/checkoutBak" */))
const _ef353368 = () => interopDefault(import('..\\pages\\quote\\detail.vue' /* webpackChunkName: "pages/quote/detail" */))
const _19ba1519 = () => interopDefault(import('..\\pages\\quote\\detail3.0.vue' /* webpackChunkName: "pages/quote/detail3.0" */))
const _a79a8ee2 = () => interopDefault(import('..\\pages\\quote\\manualInfo.vue' /* webpackChunkName: "pages/quote/manualInfo" */))
const _49d736cf = () => interopDefault(import('..\\pages\\quote\\manualNew.vue' /* webpackChunkName: "pages/quote/manualNew" */))
const _7455dc9e = () => interopDefault(import('..\\pages\\quote\\quoteIndex.vue' /* webpackChunkName: "pages/quote/quoteIndex" */))
const _3f4d700f = () => interopDefault(import('..\\pages\\quote\\quoteList.vue' /* webpackChunkName: "pages/quote/quoteList" */))
const _7bdfeb94 = () => interopDefault(import('..\\pages\\quote\\reload.vue' /* webpackChunkName: "pages/quote/reload" */))
const _45a61624 = () => interopDefault(import('..\\pages\\quote\\request.vue' /* webpackChunkName: "pages/quote/request" */))
const _5c1bdbd2 = () => interopDefault(import('..\\pages\\quote\\rfq.vue' /* webpackChunkName: "pages/quote/rfq" */))
const _81fdbf12 = () => interopDefault(import('..\\pages\\quote\\rfq-2.vue' /* webpackChunkName: "pages/quote/rfq-2" */))
const _bc650db6 = () => interopDefault(import('..\\pages\\quote\\selectCouponDialog.vue' /* webpackChunkName: "pages/quote/selectCouponDialog" */))
const _10cc3a88 = () => interopDefault(import('..\\pages\\connorsTeam\\components\\coupon.vue' /* webpackChunkName: "pages/connorsTeam/components/coupon" */))
const _0f6c05f9 = () => interopDefault(import('..\\pages\\connorsTeam\\components\\donateIntegralDialog.vue' /* webpackChunkName: "pages/connorsTeam/components/donateIntegralDialog" */))
const _ca0af1f8 = () => interopDefault(import('..\\pages\\connorsTeam\\components\\inviteTeamDialog.vue' /* webpackChunkName: "pages/connorsTeam/components/inviteTeamDialog" */))
const _06036254 = () => interopDefault(import('..\\pages\\connorsTeam\\components\\levelDescriptionDialog.vue' /* webpackChunkName: "pages/connorsTeam/components/levelDescriptionDialog" */))
const _c72a0434 = () => interopDefault(import('..\\pages\\connorsTeam\\components\\orderList.vue' /* webpackChunkName: "pages/connorsTeam/components/orderList" */))
const _f88a0a5a = () => interopDefault(import('..\\pages\\connorsTeam\\components\\pointshistoryDialog.vue' /* webpackChunkName: "pages/connorsTeam/components/pointshistoryDialog" */))
const _81a4b1d8 = () => interopDefault(import('..\\pages\\connorsTeam\\components\\quoteList.vue' /* webpackChunkName: "pages/connorsTeam/components/quoteList" */))
const _933be30a = () => interopDefault(import('..\\pages\\molds\\C\\moldsSkeleton.vue' /* webpackChunkName: "pages/molds/C/moldsSkeleton" */))
const _1692caf4 = () => interopDefault(import('..\\pages\\molds\\C\\selectProdMoldsDilog.vue' /* webpackChunkName: "pages/molds/C/selectProdMoldsDilog" */))
const _10d76188 = () => interopDefault(import('..\\pages\\molds\\C\\uploadMolding.vue' /* webpackChunkName: "pages/molds/C/uploadMolding" */))
const _0e3bebf0 = () => interopDefault(import('..\\pages\\order\\components\\AddOrderToTeam.vue' /* webpackChunkName: "pages/order/components/AddOrderToTeam" */))
const _a7fc36ce = () => interopDefault(import('..\\pages\\order\\components\\Bills.vue' /* webpackChunkName: "pages/order/components/Bills" */))
const _65cafc26 = () => interopDefault(import('..\\pages\\order\\components\\checkDfm.vue' /* webpackChunkName: "pages/order/components/checkDfm" */))
const _7d649764 = () => interopDefault(import('..\\pages\\order\\components\\checkModel.vue' /* webpackChunkName: "pages/order/components/checkModel" */))
const _03060c55 = () => interopDefault(import('..\\pages\\order\\components\\confirmAddonPay.vue' /* webpackChunkName: "pages/order/components/confirmAddonPay" */))
const _a920fd42 = () => interopDefault(import('..\\pages\\order\\components\\confirmBalance.vue' /* webpackChunkName: "pages/order/components/confirmBalance" */))
const _d746aaaa = () => interopDefault(import('..\\pages\\order\\components\\confirmPay.vue' /* webpackChunkName: "pages/order/components/confirmPay" */))
const _093b8b91 = () => interopDefault(import('..\\pages\\order\\components\\confirmPayBak.vue' /* webpackChunkName: "pages/order/components/confirmPayBak" */))
const _c94da812 = () => interopDefault(import('..\\pages\\order\\components\\confirmSample.vue' /* webpackChunkName: "pages/order/components/confirmSample" */))
const _79fe509e = () => interopDefault(import('..\\pages\\order\\components\\countdown.vue' /* webpackChunkName: "pages/order/components/countdown" */))
const _3bde612e = () => interopDefault(import('..\\pages\\order\\components\\LogisticsInfo.vue' /* webpackChunkName: "pages/order/components/LogisticsInfo" */))
const _6a3e5fa1 = () => interopDefault(import('..\\pages\\order\\components\\orderActive.vue' /* webpackChunkName: "pages/order/components/orderActive" */))
const _03de6648 = () => interopDefault(import('..\\pages\\order\\components\\orderSteps.vue' /* webpackChunkName: "pages/order/components/orderSteps" */))
const _55c62f93 = () => interopDefault(import('..\\pages\\order\\components\\orderTips.vue' /* webpackChunkName: "pages/order/components/orderTips" */))
const _45ffe07e = () => interopDefault(import('..\\pages\\order\\components\\RemoveOrderFromTeam.vue' /* webpackChunkName: "pages/order/components/RemoveOrderFromTeam" */))
const _6a10b866 = () => interopDefault(import('..\\pages\\order\\components\\Shipments.vue' /* webpackChunkName: "pages/order/components/Shipments" */))
const _67fa87aa = () => interopDefault(import('..\\pages\\order\\components\\updatePO.vue' /* webpackChunkName: "pages/order/components/updatePO" */))
const _77841359 = () => interopDefault(import('..\\pages\\order\\components\\view3d.vue' /* webpackChunkName: "pages/order/components/view3d" */))
const _1050b1ce = () => interopDefault(import('..\\pages\\order\\mixins\\ImporComponents.js' /* webpackChunkName: "pages/order/mixins/ImporComponents" */))
const _1173d399 = () => interopDefault(import('..\\pages\\quote\\quoteIndex' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a",
    component: _353d18e0,
    name: "a"
  }, {
    path: "/connorsTeam",
    component: _b52247fc,
    name: "connorsTeam"
  }, {
    path: "/mailVerify",
    component: _54e58521,
    name: "mailVerify"
  }, {
    path: "/molds",
    component: _9046812c,
    name: "molds"
  }, {
    path: "/msg",
    component: _1d711a42,
    name: "msg"
  }, {
    path: "/order",
    component: _6075ce15,
    name: "order"
  }, {
    path: "/personal",
    component: _6cf8eede,
    name: "personal"
  }, {
    path: "/quote",
    component: _7b5b6e27,
    name: "quote"
  }, {
    path: "/test",
    component: _7edaf263,
    name: "test"
  }, {
    path: "/user",
    component: _995955c8,
    name: "user",
    children: [{
      path: "password",
      component: _5bccc0b7,
      name: "user-password"
    }]
  }, {
    path: "/connorsTeam/newQuote",
    component: _48cc5cbc,
    name: "connorsTeam-newQuote"
  }, {
    path: "/enterprise/register",
    component: _3ed449d6,
    name: "enterprise-register"
  }, {
    path: "/enterprise/rfq",
    component: _7ca0913b,
    name: "enterprise-rfq"
  }, {
    path: "/help-center/specifications-policy",
    component: _425d3242,
    name: "help-center-specifications-policy"
  }, {
    path: "/landing-page/cnc-machining",
    component: _58cfd8ae,
    name: "landing-page-cnc-machining"
  }, {
    path: "/landing-page/competitors",
    component: _951bb07e,
    name: "landing-page-competitors"
  }, {
    path: "/member/agreement",
    component: _ddb3daa2,
    name: "member-agreement"
  }, {
    path: "/member/findPassword",
    component: _067c09df,
    name: "member-findPassword"
  }, {
    path: "/member/joinTeam",
    component: _681abd12,
    name: "member-joinTeam"
  }, {
    path: "/member/login",
    component: _3b7711e4,
    name: "member-login"
  }, {
    path: "/member/privacy",
    component: _a5975726,
    name: "member-privacy"
  }, {
    path: "/member/register",
    component: _88a8c1e4,
    name: "member-register"
  }, {
    path: "/member/registerBak",
    component: _6645184e,
    name: "member-registerBak"
  }, {
    path: "/member/resetPassword",
    component: _b12f21a2,
    name: "member-resetPassword"
  }, {
    path: "/member/thank-you",
    component: _b5f57b3e,
    name: "member-thank-you"
  }, {
    path: "/order/detail",
    component: _4697041e,
    name: "order-detail"
  }, {
    path: "/order/orderSubmit",
    component: _cbff7fee,
    name: "order-orderSubmit"
  }, {
    path: "/order/paybank",
    component: _62863067,
    name: "order-paybank"
  }, {
    path: "/order/payment",
    component: _a56a42ee,
    name: "order-payment"
  }, {
    path: "/order/paystatus",
    component: _2237165d,
    name: "order-paystatus"
  }, {
    path: "/order/print",
    component: _257bf270,
    name: "order-print"
  }, {
    path: "/order/utils",
    component: _86c45aca,
    name: "order-utils"
  }, {
    path: "/quote/blank",
    component: _0dabc5a9,
    name: "quote-blank"
  }, {
    path: "/quote/checkout",
    component: _7b3dac01,
    name: "quote-checkout"
  }, {
    path: "/quote/checkoutBak",
    component: _a953fb0a,
    name: "quote-checkoutBak"
  }, {
    path: "/quote/detail",
    component: _ef353368,
    name: "quote-detail"
  }, {
    path: "/quote/detail3.0",
    component: _19ba1519,
    name: "quote-detail3.0"
  }, {
    path: "/quote/manualInfo",
    component: _a79a8ee2,
    name: "quote-manualInfo"
  }, {
    path: "/quote/manualNew",
    component: _49d736cf,
    name: "quote-manualNew"
  }, {
    path: "/quote/quoteIndex",
    component: _7455dc9e,
    name: "quote-quoteIndex"
  }, {
    path: "/quote/quoteList",
    component: _3f4d700f,
    name: "quote-quoteList"
  }, {
    path: "/quote/reload",
    component: _7bdfeb94,
    name: "quote-reload"
  }, {
    path: "/quote/request",
    component: _45a61624,
    name: "quote-request"
  }, {
    path: "/quote/rfq",
    component: _5c1bdbd2,
    name: "quote-rfq"
  }, {
    path: "/quote/rfq-2",
    component: _81fdbf12,
    name: "quote-rfq-2"
  }, {
    path: "/quote/selectCouponDialog",
    component: _bc650db6,
    name: "quote-selectCouponDialog"
  }, {
    path: "/connorsTeam/components/coupon",
    component: _10cc3a88,
    name: "connorsTeam-components-coupon"
  }, {
    path: "/connorsTeam/components/donateIntegralDialog",
    component: _0f6c05f9,
    name: "connorsTeam-components-donateIntegralDialog"
  }, {
    path: "/connorsTeam/components/inviteTeamDialog",
    component: _ca0af1f8,
    name: "connorsTeam-components-inviteTeamDialog"
  }, {
    path: "/connorsTeam/components/levelDescriptionDialog",
    component: _06036254,
    name: "connorsTeam-components-levelDescriptionDialog"
  }, {
    path: "/connorsTeam/components/orderList",
    component: _c72a0434,
    name: "connorsTeam-components-orderList"
  }, {
    path: "/connorsTeam/components/pointshistoryDialog",
    component: _f88a0a5a,
    name: "connorsTeam-components-pointshistoryDialog"
  }, {
    path: "/connorsTeam/components/quoteList",
    component: _81a4b1d8,
    name: "connorsTeam-components-quoteList"
  }, {
    path: "/molds/C/moldsSkeleton",
    component: _933be30a,
    name: "molds-C-moldsSkeleton"
  }, {
    path: "/molds/C/selectProdMoldsDilog",
    component: _1692caf4,
    name: "molds-C-selectProdMoldsDilog"
  }, {
    path: "/molds/C/uploadMolding",
    component: _10d76188,
    name: "molds-C-uploadMolding"
  }, {
    path: "/order/components/AddOrderToTeam",
    component: _0e3bebf0,
    name: "order-components-AddOrderToTeam"
  }, {
    path: "/order/components/Bills",
    component: _a7fc36ce,
    name: "order-components-Bills"
  }, {
    path: "/order/components/checkDfm",
    component: _65cafc26,
    name: "order-components-checkDfm"
  }, {
    path: "/order/components/checkModel",
    component: _7d649764,
    name: "order-components-checkModel"
  }, {
    path: "/order/components/confirmAddonPay",
    component: _03060c55,
    name: "order-components-confirmAddonPay"
  }, {
    path: "/order/components/confirmBalance",
    component: _a920fd42,
    name: "order-components-confirmBalance"
  }, {
    path: "/order/components/confirmPay",
    component: _d746aaaa,
    name: "order-components-confirmPay"
  }, {
    path: "/order/components/confirmPayBak",
    component: _093b8b91,
    name: "order-components-confirmPayBak"
  }, {
    path: "/order/components/confirmSample",
    component: _c94da812,
    name: "order-components-confirmSample"
  }, {
    path: "/order/components/countdown",
    component: _79fe509e,
    name: "order-components-countdown"
  }, {
    path: "/order/components/LogisticsInfo",
    component: _3bde612e,
    name: "order-components-LogisticsInfo"
  }, {
    path: "/order/components/orderActive",
    component: _6a3e5fa1,
    name: "order-components-orderActive"
  }, {
    path: "/order/components/orderSteps",
    component: _03de6648,
    name: "order-components-orderSteps"
  }, {
    path: "/order/components/orderTips",
    component: _55c62f93,
    name: "order-components-orderTips"
  }, {
    path: "/order/components/RemoveOrderFromTeam",
    component: _45ffe07e,
    name: "order-components-RemoveOrderFromTeam"
  }, {
    path: "/order/components/Shipments",
    component: _6a10b866,
    name: "order-components-Shipments"
  }, {
    path: "/order/components/updatePO",
    component: _67fa87aa,
    name: "order-components-updatePO"
  }, {
    path: "/order/components/view3d",
    component: _77841359,
    name: "order-components-view3d"
  }, {
    path: "/order/mixins/ImporComponents",
    component: _1050b1ce,
    name: "order-mixins-ImporComponents"
  }, {
    path: "/",
    component: _1173d399,
    name: "/"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
